<template>
    <v-dialog v-model="dialog" persistent max-width="1000px">
        <v-card>
            <v-card-title class="pa-0">
                <v-toolbar color="primary" elevation="0" dark>
                    {{ editedIndex == -1 ? $t("add") : $t("edit") }}

                    {{ $t("events.singleEventTypes.quote") }}
                </v-toolbar>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="12">
                            <tiptap-vuetify
                                v-model="editedItem.content"
                                :card-props="{
                                    outlined: true
                                }"
                                :extensions="extensions"
                                :toolbar-attributes="{
                                    color: $vuetify.theme.dark ? '' : '#EEEEEE'
                                }"
                            />
                        </v-col>
                        <v-col cols="12">
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        hide-details
                                        dense
                                        v-model="editedItem.publishedAt"
                                        :label="$t('events.publishedAt')"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="editedItem.publishedAt"
                                    @input="menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    v-if="
                        isInRole(roles.event.create) ||
                            isInRole(roles.event.update)
                    "
                    color="primary darken-1 white--text"
                    @click="save"
                    :min-width="100"
                    :loading="loading"
                >
                    <v-icon>mdi-content-save-outline</v-icon>
                    {{ $t("save") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="closeDialog">
                    {{ $t("cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from "axios";
import moment from "moment";
import { DataValidation } from "../../BusinessLogic/DataValidation.js";

const dataValidation = new DataValidation();

import {
    TiptapVuetify,
    Heading,
    Bold,
    Italic,
    Strike,
    Underline,
    Code,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem,
    Link,
    Blockquote,
    HardBreak,
    HorizontalRule,
    History
} from "tiptap-vuetify";

export default {
    components: {
        TiptapVuetify
    },
    props: ["editedItem", "editedIndex", "dialog", "close", "refreshData"],
    data() {
        return {
            extensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [
                    Heading,
                    {
                        options: {
                            levels: [1, 2, 3]
                        }
                    }
                ],
                Bold,
                Code,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],
            menu: false,
            disabled: false,
            loading: false
        };
    },
    watch: {
        editedItem() {
            if (
                this.editedItem.publishedAt != undefined &&
                this.editedItem.publishedAt != null
            )
                this.editedItem.publishedAt = moment(
                    this.editedItem.publishedAt
                ).format("YYYY-MM-DD");
        },
        dialog(val) {
            val || this.closeDialog();
        }
    },
    methods: {
        closeDialog() {
            this.close();
        },
        save() {
            try {
                if (
                    dataValidation.CheckStringValidation(
                        this.editedItem.content
                    )
                ) {
                    if (
                        !dataValidation.CheckGuidValidation(
                            this.editedItem.eventGuid
                        )
                    ) {
                        this.loading = true;
                        axios
                            .post("Events/CreateQuote", {
                                content: this.editedItem.content,
                                publishedAt: this.editedItem.publishedAt
                            })
                            .then(response => {
                                if (
                                    response.data.status ==
                                    this.responseStatus.ok
                                ) {
                                    this.$toast.success(
                                        this.$t(
                                            "operationAccomplishedSuccessfully"
                                        )
                                    );
                                    this.close();
                                } else {
                                    this.$toast.error(
                                        this.$t(
                                            "error." + response.data.message
                                        )
                                    );
                                }
                            })
                            .catch(e => {
                                this.$toast.error(
                                    this.$t("AnErrorOccurredDuringTheProcess")
                                );
                                console.log(e);
                            })
                            .finally(() => {
                                this.loading = false;
                                this.refreshData();
                            });
                    } else {
                        this.loading = true;
                        axios
                            .put("Events/UpdateQuote", {
                                eventGuid: this.editedItem.eventGuid,
                                content: this.editedItem.content,
                                publishedAt: this.editedItem.publishedAt
                            })
                            .then(response => {
                                if (
                                    response.data.status ==
                                    this.responseStatus.ok
                                ) {
                                    this.$toast.success(
                                        this.$t(
                                            "operationAccomplishedSuccessfully"
                                        )
                                    );
                                    this.close();
                                } else {
                                    this.$toast.error(
                                        this.$t(
                                            "error." + response.data.message
                                        )
                                    );
                                }
                            })
                            .catch(e => {
                                this.$toast.error(
                                    this.$t("AnErrorOccurredDuringTheProcess")
                                );
                                console.log(e);
                            })
                            .finally(() => {
                                this.loading = false;
                                this.refreshData();
                            });
                    }
                } else
                    this.$toast.warning(
                        this.$t("events.warnings.pleaseWriteQuoteContent")
                    );
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>
