var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"color":"primary","elevation":"0","dark":""}},[_vm._v(" "+_vm._s(_vm.editedIndex == -1 ? _vm.$t("add") : _vm.$t("edit"))+" "+_vm._s(_vm.$t("events.singleEventTypes.quote"))+" ")])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('tiptap-vuetify',{attrs:{"card-props":{
                                outlined: true
                            },"extensions":_vm.extensions,"toolbar-attributes":{
                                color: _vm.$vuetify.theme.dark ? '' : '#EEEEEE'
                            }},model:{value:(_vm.editedItem.content),callback:function ($$v) {_vm.$set(_vm.editedItem, "content", $$v)},expression:"editedItem.content"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","hide-details":"","dense":"","label":_vm.$t('events.publishedAt'),"readonly":""},model:{value:(_vm.editedItem.publishedAt),callback:function ($$v) {_vm.$set(_vm.editedItem, "publishedAt", $$v)},expression:"editedItem.publishedAt"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.editedItem.publishedAt),callback:function ($$v) {_vm.$set(_vm.editedItem, "publishedAt", $$v)},expression:"editedItem.publishedAt"}})],1)],1)],1)],1)],1),_c('v-card-actions',[(
                    _vm.isInRole(_vm.roles.event.create) ||
                        _vm.isInRole(_vm.roles.event.update)
                )?_c('v-btn',{attrs:{"color":"primary darken-1 white--text","min-width":100,"loading":_vm.loading},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }