var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('Page-Header',{attrs:{"title":_vm.eventTypes[_vm.eventType].text,"icon":"mdi-newspaper-variant-multiple-outline"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [(_vm.isInRole(_vm.roles.event.create))?_c('v-btn',{attrs:{"color":"primary darken-1"},nativeOn:{"click":function($event){return _vm.addItem($event)}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("add"))+" ")],1):_vm._e()]},proxy:true}])}),_c('v-card-text',[_c('v-data-table',{staticStyle:{"overflow":"hidden"},attrs:{"page":_vm.page,"pageCount":_vm.numberOfPages,"options":_vm.options,"server-items-length":_vm.totalRecords,"headers":_vm.headers,"items":_vm.events,"loading":_vm.loading,"footer-props":{
                    'items-per-page-options': [10, 20, 100, 200]
                },"items-per-page":20,"loading-text":_vm.$t('loading')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('Event-Dialog',{key:_vm.dialog,attrs:{"dialog":_vm.dialog,"close":_vm.close,"editedIndex":_vm.editedIndex,"editedItem":_vm.editedItem,"refreshData":_vm.refreshTable,"eventType":_vm.eventType}}),_c('Quote-Dialog',{attrs:{"dialog":_vm.quoteDialog,"close":_vm.quoteClose,"editedIndex":_vm.editedIndex,"editedItem":_vm.editedItem,"refreshData":_vm.refreshTable}}),_c('v-text-field',{attrs:{"dense":"","outlined":"","append-icon":"mdi-magnify","loading":_vm.loading,"label":_vm.$t('search'),"single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.refreshTable($event)},"click:append":_vm.refreshTable},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete,"toolBarColor":"red darken-2"}})]},proxy:true},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(_vm.isInRole(_vm.roles.event.update))?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [(_vm.isInRole(_vm.roles.event.delete))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("delete")))])])],1)],1)]}},{key:"item.publishedAt",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.publishedAt, _vm.eventType != 4 ? "yyyy-MM-DD hh:mm A" : "yyyy-MM-DD" ))+" ")]}},{key:"item.isPinned",fn:function(ref){
                var item = ref.item;
return [(item.isPinned)?_c('v-icon',{attrs:{"color":_vm.$vuetify.theme.dark
                                ? 'primary lighten-3'
                                : 'primary'}},[_vm._v("mdi-pin")]):_vm._e()]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }