<template>
    <v-dialog v-model="dialog" persistent max-width="1000px">
        <v-card>
            <v-card-title class="pa-0">
                <v-toolbar color="primary" elevation="0" dark>
                    {{ editedIndex == -1 ? $t("add") : $t("edit") }}

                    {{ eventTypes[eventType].text }}
                </v-toolbar>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    v-model="editedItem.title"
                                    :rules="rules"
                                    :label="$t('events.title')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <tiptap-vuetify
                                    v-model="editedItem.content"
                                    :extensions="extensions"
                                    :card-props="{
                                        outlined: true
                                    }"
                                    :toolbar-attributes="{
                                        color: $vuetify.theme.dark
                                            ? ''
                                            : '#EEEEEE'
                                    }"
                                />
                            </v-col>
                            <!-- Cover Image -->
                            <v-col
                                v-if="
                                    uploadedCoverImage != undefined &&
                                        uploadedCoverImage != null
                                "
                                cols="12"
                                md="6"
                            >
                                <Custom-Image
                                    :image="uploadedCoverImage"
                                    :maxWidth="250"
                                    :onDelete="deleteFile"
                                />
                            </v-col>
                            <v-col
                                v-if="coverPreviewPath != null"
                                cols="12"
                                md="6"
                            >
                                <v-img
                                    max-width="250"
                                    :src="coverPreviewPath"
                                ></v-img>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-file-input
                                    v-model="coverImage"
                                    small-chips
                                    show-size
                                    :label="$t('events.coverImage')"
                                    hide-details
                                    @click:clear="clearFiles"
                                    @change="inputChanged"
                                >
                                </v-file-input>
                            </v-col>
                            <!-- Attachments -->
                            <v-col
                                v-if="
                                    eventFiles != undefined &&
                                        (eventFiles != null) &
                                            (eventFiles.length > 0)
                                "
                                cols="12"
                                md="12"
                            >
                                <v-row no-gutters>
                                    <v-col cols="12"
                                        ><p class="black--text">
                                            {{ $t("attachments") }}
                                        </p></v-col
                                    >
                                    <v-col cols="12">
                                        <v-card
                                            class="overflow-y-auto"
                                            max-height="300"
                                            elevation="0"
                                        >
                                            <v-card-text>
                                                <v-row>
                                                    <v-col
                                                        v-for="file in eventFiles"
                                                        :key="file.name"
                                                        cols="12"
                                                        md="4"
                                                    >
                                                        <Download-File
                                                            :iconWidth="50"
                                                            :fileSizeFormat="
                                                                file.size
                                                            "
                                                            :onDelete="
                                                                deleteFile
                                                            "
                                                            :onClose="
                                                                closeDeleteFile
                                                            "
                                                            :file="file"
                                                            :fileUrl="file.path"
                                                        ></Download-File>
                                                    </v-col> </v-row
                                            ></v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-file-input
                                    v-model="currFiles"
                                    small-chips
                                    show-size
                                    :label="$t('attachments')"
                                    multiple
                                    hide-details
                                    @click:clear="clearFiles"
                                    @change="inputChanged"
                                >
                                    <template
                                        v-slot:selection="{ index, text }"
                                    >
                                        <v-chip
                                            close
                                            @click:close="deleteChip(index)"
                                        >
                                            {{ text }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-checkbox
                                    v-model="editedItem.isPinned"
                                    :label="$t('events.pin')"
                                ></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-checkbox
                                    v-model="editedItem.isItPublished"
                                    :label="$t('events.publish')"
                                ></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    v-if="
                        isInRole(roles.event.create) ||
                            isInRole(roles.event.update)
                    "
                    color="primary darken-1 white--text"
                    @click="save"
                    :disabled="!valid"
                    :min-width="100"
                    :loading="loading"
                >
                    <v-icon>mdi-content-save-outline</v-icon>
                    {{ $t("save") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="closeDialog">
                    {{ $t("cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <!--Delete file confirm-->
        <confirm-dialog
            :openDialog="deleteFileDialog"
            :onClicked="deleteFileConfirm"
            :onClose="closeDeleteFile"
            toolBarColor="red darken-2"
        ></confirm-dialog>
    </v-dialog>
</template>
<script>
import axios from "axios";
import DownloadFile from "../../components/downloadFile.vue";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import CustomImage from "../../components/CustomImage.vue";

import {
    TiptapVuetify,
    Heading,
    Bold,
    Italic,
    Strike,
    Underline,
    Code,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem,
    Link,
    Blockquote,
    HardBreak,
    HorizontalRule,
    History
} from "tiptap-vuetify";

export default {
    components: {
        DownloadFile,
        ConfirmDialog,
        TiptapVuetify,
        CustomImage
    },
    props: [
        "editedItem",
        "editedIndex",
        "dialog",
        "close",
        "refreshData",
        "eventType"
    ],
    data() {
        return {
            coverPreviewPath: null,
            extensions: [
                History,
                Blockquote,
                Link,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                [
                    Heading,
                    {
                        options: {
                            levels: [1, 2, 3]
                        }
                    }
                ],
                Bold,
                Code,
                HorizontalRule,
                Paragraph,
                HardBreak
            ],
            eventTypes: [
                {
                    text: this.$t("events.singleEventTypes.news"),
                    value: 0
                },
                {
                    text: this.$t("events.singleEventTypes.advertisement"),
                    value: 1
                },
                {
                    text: this.$t("events.singleEventTypes.project"),
                    value: 2
                },
                {
                    text: this.$t("events.singleEventTypes.blog"),
                    value: 3
                },
                {
                    text: this.$t("events.singleEventTypes.quote"),
                    value: 4
                }
            ],
            coverImage: null,
            uploadedCoverImage: null,
            tab: null,
            disabled: false,
            eventFiles: [],
            deletedFile: null,
            deleteFileDialog: false,
            currFiles: [],
            files: [],
            valid: true,
            loading: false,
            rules: [value => !!value || this.$t("required")]
        };
    },
    created() {
        if (
            this.editedItem.eventGuid != undefined &&
            this.editedItem.eventGuid != null &&
            this.editedItem.eventGuid !=
                "00000000-0000-0000-0000-000000000000" &&
            this.editedItem.eventGuid != ""
        ) {
            this.disabled = true;
            this.getEventAttachments(this.editedItem.eventGuid);
        }
    },
    watch: {
        dialog(val) {
            val || this.closeDialog();
        }
    },
    methods: {
        closeDialog() {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.currFiles = [];
            this.files = [];
            this.close();
        },
        save() {
            try {
                if (this.$refs.form.validate()) {
                    if (
                        this.editedItem.eventGuid == "" ||
                        this.editedItem.eventGuid == undefined
                    ) {
                        var data = new FormData();
                        var validFiles = true;

                        //cover image
                        data.append("coverImage", this.coverImage);

                        //attachments
                        for (let i = 0; i < this.files.length; i++) {
                            var file = this.files[i];
                            if (file != null && file.size <= 20971520) {
                                data.append("files", file);
                            } else {
                                validFiles = false;
                                break;
                            }
                        }

                        //event data
                        var dto = {
                            eventGuid:
                                this.editedItem.eventGuid != undefined &&
                                this.editedItem.eventGuid != ""
                                    ? this.editedItem.eventGuid
                                    : null,
                            title: this.editedItem.title,
                            content: this.editedItem.content,
                            isPinned: this.editedItem.isPinned,
                            eventType: this.eventType,
                            isItPublished: this.editedItem.isItPublished
                        };

                        for (var key in dto) {
                            if (dto[key]) data.append(key, dto[key]);
                        }

                        if (!validFiles) {
                            this.$toast.error(
                                this.$t("error.MaxFileSizeReached")
                            );
                            return;
                        }

                        this.loading = true;
                        axios
                            .post("Events/Create", data)
                            .then(response => {
                                if (
                                    response.data.status ==
                                    this.responseStatus.ok
                                ) {
                                    this.$toast.success(
                                        this.$t(
                                            "operationAccomplishedSuccessfully"
                                        )
                                    );
                                    this.close();
                                    this.files = [];
                                    this.currFiles = [];
                                } else {
                                    this.$toast.error(
                                        this.$t(
                                            "error." + response.data.message
                                        )
                                    );
                                }
                            })
                            .catch(e => {
                                this.$toast.error(
                                    this.$t("AnErrorOccurredDuringTheProcess")
                                );
                                console.log(e);
                            })
                            .finally(() => {
                                this.loading = false;
                                this.refreshData();
                            });
                    } else {
                        var data1 = new FormData();
                        var validFiles1 = true;

                        for (let i = 0; i < this.files.length; i++) {
                            var file1 = this.files[i];
                            if (file1 != null && file1.size <= 20971520) {
                                data1.append("files", file1);
                            } else {
                                validFiles1 = false;
                                break;
                            }
                        }

                        if (!validFiles1) {
                            this.$toast.error(
                                this.$t("error.MaxFileSizeReached")
                            );
                            return;
                        }

                        //cover image
                        data1.append("coverImage", this.coverImage);

                        //event data
                        var dto1 = {
                            eventGuid:
                                this.editedItem.eventGuid != undefined &&
                                this.editedItem.eventGuid != ""
                                    ? this.editedItem.eventGuid
                                    : null,
                            title: this.editedItem.title,
                            content: this.editedItem.content,
                            isPinned: this.editedItem.isPinned,
                            eventType: this.eventType,
                            isItPublished: this.editedItem.isItPublished
                        };

                        for (var key1 in dto1) {
                            if (dto1[key1]) data1.append(key1, dto1[key1]);
                        }

                        this.loading = true;
                        axios
                            .put("Events/Update", data1)
                            .then(response => {
                                if (
                                    response.data.status ==
                                    this.responseStatus.ok
                                ) {
                                    this.$toast.success(
                                        this.$t(
                                            "operationAccomplishedSuccessfully"
                                        )
                                    );
                                    this.close();
                                    this.files = [];
                                    this.currFiles = [];
                                } else {
                                    this.$toast.error(
                                        this.$t(
                                            "error." + response.data.message
                                        )
                                    );
                                }
                            })
                            .catch(e => {
                                this.$toast.error(
                                    this.$t("AnErrorOccurredDuringTheProcess")
                                );
                                console.log(e);
                            })
                            .finally(() => {
                                this.loading = false;
                                this.refreshData();
                            });
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
        inputChanged(e) {
            try {
                this.files = [...this.currFiles, ...this.files];
                this.coverPreviewPath = URL.createObjectURL(e);
                // eslint-disable-next-line no-empty
            } catch (error) {}
        },
        getEventAttachments(id) {
            try {
                this.loading = true;

                axios
                    .get(`Events/Get?id=${id}`)
                    .then(response => {
                        this.eventFiles = response.data.data.attachments;
                        this.uploadedCoverImage =
                            response.data.data.event.coverImage;
                    })
                    .catch(e => {
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        deleteFileConfirm() {
            axios
                .post("Events/DeleteEventAttachment", {
                    fileName: this.deletedFile,
                    baseGuid: this.editedItem.eventGuid
                })
                .then(response => {
                    if (response.data.status == this.responseStatus.ok) {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                        this.getEventAttachments(this.editedItem.eventGuid);
                        this.deleteFileDialog = false;
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.refreshData();
                    this.deleteFileDialog = false;
                    console.log(e);
                });
        },
        deleteFile(file) {
            this.deletedFile = file;
            this.deleteFileDialog = true;
        },
        closeDeleteFile() {
            this.deleteFileDialog = false;
            this.currFiles = [];
            this.files = [];
        },
        downloadFile(file) {
            window.open(file.path);
        },
        clearFiles() {
            try {
                this.files = [];
            } catch (error) {
                console.log(error);
            }
        },
        deleteChip(index) {
            this.files.splice(index, 1);
            this.currFiles.splice(index, 1);
        }
    }
};
</script>
