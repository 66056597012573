import axios from "axios";

export default class EventsApi {
    static GetById(id) {
        return axios.get("Events/Get?id=" + id).then(response => response);
    }

    static GetAll(eventType, itemsPerPage, pageNumber, keyword) {
        return axios
            .get("Events/Get", {
                params: {
                    eventType: eventType,
                    size: itemsPerPage,
                    page: pageNumber,
                    keyword: keyword
                }
            })
            .then(response => response);
    }
}
