var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"1000px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"color":"primary","elevation":"0","dark":""}},[_vm._v(" "+_vm._s(_vm.editedIndex == -1 ? _vm.$t("add") : _vm.$t("edit"))+" "+_vm._s(_vm.eventTypes[_vm.eventType].text)+" ")])],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","dense":"","rules":_vm.rules,"label":_vm.$t('events.title')},model:{value:(_vm.editedItem.title),callback:function ($$v) {_vm.$set(_vm.editedItem, "title", $$v)},expression:"editedItem.title"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('tiptap-vuetify',{attrs:{"extensions":_vm.extensions,"card-props":{
                                    outlined: true
                                },"toolbar-attributes":{
                                    color: _vm.$vuetify.theme.dark
                                        ? ''
                                        : '#EEEEEE'
                                }},model:{value:(_vm.editedItem.content),callback:function ($$v) {_vm.$set(_vm.editedItem, "content", $$v)},expression:"editedItem.content"}})],1),(
                                _vm.uploadedCoverImage != undefined &&
                                    _vm.uploadedCoverImage != null
                            )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('Custom-Image',{attrs:{"image":_vm.uploadedCoverImage,"maxWidth":250,"onDelete":_vm.deleteFile}})],1):_vm._e(),(_vm.coverPreviewPath != null)?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-img',{attrs:{"max-width":"250","src":_vm.coverPreviewPath}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-file-input',{attrs:{"small-chips":"","show-size":"","label":_vm.$t('events.coverImage'),"hide-details":""},on:{"click:clear":_vm.clearFiles,"change":_vm.inputChanged},model:{value:(_vm.coverImage),callback:function ($$v) {_vm.coverImage=$$v},expression:"coverImage"}})],1),(
                                _vm.eventFiles != undefined &&
                                    (_vm.eventFiles != null) &
                                        (_vm.eventFiles.length > 0)
                            )?_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"black--text"},[_vm._v(" "+_vm._s(_vm.$t("attachments"))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"overflow-y-auto",attrs:{"max-height":"300","elevation":"0"}},[_c('v-card-text',[_c('v-row',_vm._l((_vm.eventFiles),function(file){return _c('v-col',{key:file.name,attrs:{"cols":"12","md":"4"}},[_c('Download-File',{attrs:{"iconWidth":50,"fileSizeFormat":file.size,"onDelete":_vm.deleteFile,"onClose":_vm.closeDeleteFile,"file":file,"fileUrl":file.path}})],1)}),1)],1)],1)],1)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-file-input',{attrs:{"small-chips":"","show-size":"","label":_vm.$t('attachments'),"multiple":"","hide-details":""},on:{"click:clear":_vm.clearFiles,"change":_vm.inputChanged},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
                            var index = ref.index;
                            var text = ref.text;
return [_c('v-chip',{attrs:{"close":""},on:{"click:close":function($event){return _vm.deleteChip(index)}}},[_vm._v(" "+_vm._s(text)+" ")])]}}]),model:{value:(_vm.currFiles),callback:function ($$v) {_vm.currFiles=$$v},expression:"currFiles"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('events.pin')},model:{value:(_vm.editedItem.isPinned),callback:function ($$v) {_vm.$set(_vm.editedItem, "isPinned", $$v)},expression:"editedItem.isPinned"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-checkbox',{attrs:{"label":_vm.$t('events.publish')},model:{value:(_vm.editedItem.isItPublished),callback:function ($$v) {_vm.$set(_vm.editedItem, "isItPublished", $$v)},expression:"editedItem.isItPublished"}})],1)],1)],1)],1)],1),_c('v-card-actions',[(
                    _vm.isInRole(_vm.roles.event.create) ||
                        _vm.isInRole(_vm.roles.event.update)
                )?_c('v-btn',{attrs:{"color":"primary darken-1 white--text","disabled":!_vm.valid,"min-width":100,"loading":_vm.loading},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save-outline")]),_vm._v(" "+_vm._s(_vm.$t("save"))+" ")],1):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.closeDialog}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])],1)],1),_c('confirm-dialog',{attrs:{"openDialog":_vm.deleteFileDialog,"onClicked":_vm.deleteFileConfirm,"onClose":_vm.closeDeleteFile,"toolBarColor":"red darken-2"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }