<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header
                :title="eventTypes[eventType].text"
                icon="mdi-newspaper-variant-multiple-outline"
            >
                <template v-slot:buttons>
                    <v-btn
                        v-if="isInRole(roles.event.create)"
                        color="primary darken-1"
                        @click.native="addItem"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-card-text>
                <v-data-table
                    :page="page"
                    :pageCount="numberOfPages"
                    :options.sync="options"
                    :server-items-length="totalRecords"
                    :headers="headers"
                    :items="events"
                    :loading="loading"
                    :footer-props="{
                        'items-per-page-options': [10, 20, 100, 200]
                    }"
                    :items-per-page="20"
                    style="overflow: hidden;"
                    :loading-text="$t('loading')"
                >
                    <template v-slot:top>
                        <Event-Dialog
                            :dialog="dialog"
                            :close="close"
                            :editedIndex="editedIndex"
                            :editedItem="editedItem"
                            :refreshData="refreshTable"
                            :key="dialog"
                            :eventType="eventType"
                        />
                        <Quote-Dialog
                            :dialog="quoteDialog"
                            :close="quoteClose"
                            :editedIndex="editedIndex"
                            :editedItem="editedItem"
                            :refreshData="refreshTable"
                        />
                        <!-- Search bar -->
                        <v-text-field
                            dense
                            outlined
                            @keydown.enter="refreshTable"
                            append-icon="mdi-magnify"
                            @click:append="refreshTable"
                            :loading="loading"
                            v-model="search"
                            :label="$t('search')"
                            single-line
                            hide-details
                        ></v-text-field>
                        <!-- Delete popup -->
                        <confirm-dialog
                            :openDialog="dialogDelete"
                            :onClicked="deleteItemConfirm"
                            :onClose="closeDelete"
                            toolBarColor="red darken-2"
                        ></confirm-dialog>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-row>
                            <v-col cols="6"
                                ><v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-if="isInRole(roles.event.update)"
                                            class="mr-2"
                                            @click="editItem(item)"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span> {{ $t("edit") }} </span>
                                </v-tooltip></v-col
                            >
                            <v-col cols="6"
                                ><v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-if="isInRole(roles.event.delete)"
                                            @click="deleteItem(item)"
                                            v-bind="attrs"
                                            v-on="on"
                                            color="red"
                                        >
                                            mdi-delete-outline
                                        </v-icon>
                                    </template>
                                    <span> {{ $t("delete") }}</span>
                                </v-tooltip></v-col
                            >
                        </v-row>
                    </template>
                    <template v-slot:[`item.publishedAt`]="{ item }">
                        {{
                            item.publishedAt
                                | moment(
                                    eventType != 4
                                        ? "yyyy-MM-DD hh:mm A"
                                        : "yyyy-MM-DD"
                                )
                        }}
                    </template>
                    <template v-slot:[`item.isPinned`]="{ item }">
                        <v-icon
                            :color="
                                $vuetify.theme.dark
                                    ? 'primary lighten-3'
                                    : 'primary'
                            "
                            v-if="item.isPinned"
                            >mdi-pin</v-icon
                        >
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";
import EventDialog from "./EventDialog.vue";
import QuoteDialog from "./QuoteDialog.vue";
import EventsApi from "../../Api/EventsApi";

export default {
    components: {
        ConfirmDialog,
        PageHeader,
        EventDialog,
        QuoteDialog
    },
    data() {
        return {
            page: 1,
            totalRecords: 0,
            numberOfPages: 0,
            options: {},

            valid: true,
            loading: true,
            dialog: false,
            quoteDialog: false,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            eventType: this.$route.params.eventType,
            eventTypes: [
                {
                    text: this.$t("events.eventTypes.news"),
                    value: 0
                },
                {
                    text: this.$t("events.eventTypes.advertisements"),
                    value: 1
                },
                {
                    text: this.$t("events.eventTypes.projects"),
                    value: 2
                },
                {
                    text: this.$t("events.eventTypes.blogs"),
                    value: 3
                },
                {
                    text: this.$t("events.eventTypes.quotes"),
                    value: 4
                }
            ],
            events: [],
            editedItem: {
                eventGuid: "",
                title: "",
                details: ""
            },
            defaultItem: {
                eventGuid: "",
                title: "",
                details: ""
            },
            rules: [value => !!value || this.$t("required")]
        };
    },
    mounted() {
        if (!this.isInRole(this.roles.event.window))
            this.redirectUnauthorizedUsers();
        this.refreshTable();
    },
    computed: {
        headers() {
            if (this.eventType == 4) {
                return [
                    {
                        text: this.$t("events.content"),
                        value: "strippedContent"
                    },
                    {
                        text: this.$t("events.publishedAt"),
                        value: "publishedAt"
                    },
                    { text: "", value: "actions" }
                ];
            } else if (this.eventType == 5) {
                return [
                    {
                        text: this.$t("events.title"),
                        value: "title"
                    },
                    {
                        text: this.$t("events.content"),
                        value: "strippedContent"
                    },
                    {
                        text: this.$t("events.displayedUpTo"),
                        value: "publishedAt"
                    },
                    { text: "", value: "actions" }
                ];
            } else
                return [
                    {
                        text: this.$t("events.title"),
                        value: "title"
                    },
                    {
                        text: this.$t("events.content"),
                        value: "strippedContent"
                    },
                    {
                        text: this.$t("events.publishedAt"),
                        value: "publishedAt"
                    },
                    {
                        text: this.$t("events.isPinned"),
                        value: "isPinned"
                    },
                    {
                        text: this.$t("events.viewsCount"),
                        value: "viewsCount"
                    },
                    { text: "", value: "actions" }
                ];
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
        options: {
            handler() {
                this.refreshTable();
            }
        }
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.events.indexOf(item);
            this.editedItem = Object.assign({}, item);
            if (this.eventType == 4) this.quoteDialog = true;
            else this.dialog = true;
        },
        addItem() {
            this.editedIndex = -1;
            this.editedItem = Object.assign({}, this.defaultItem);
            this.editedItem.numberOfDays = 0;

            if (this.eventType == 4) this.quoteDialog = true;
            else this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.events.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            try {
                var deleteItem = this.events[this.editedIndex];

                axios
                    .delete("Events/Delete?guid=" + deleteItem.eventGuid)
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.closeDelete();
                        this.refreshTable();
                    });
            } catch (error) {
                console.log(error);
            }
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        quoteClose() {
            this.quoteDialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        refreshTable() {
            try {
                this.loading = true;

                var eventType =
                    this.eventType != undefined && this.eventType != null
                        ? this.eventType
                        : 0;

                const { page, itemsPerPage } = this.options;

                EventsApi.GetAll(eventType, itemsPerPage, page, this.search)
                    .then(response => {
                        this.events = response.data.data.events;
                        this.totalRecords = response.data.data.totalRecords;
                        this.numberOfPages = response.data.data.numberOfPages;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>
